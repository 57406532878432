// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"40fba132eee265c76bdd933afa3c47b2e5b83784"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: "https://7f21823b41cf894f29d63df4f9278edd@o4507901563633664.ingest.de.sentry.io/4507901778329680",

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
});
